import { Stack, Box, Heading, SimpleGrid } from "@chakra-ui/react";
import { useParams } from "react-router-dom";

import { DataListItem, DataListRoot } from "../../../codegen/ui/data-list";

import { useGetHohishHohishesHohishUuidGet } from "../../../codegen/openapi/queries";
import { HohishType } from "../../../codegen/openapi/requests/types.gen";

interface FieldType {
  field: keyof HohishType;
  label: string;
}

const identityFields: FieldType[] = [
  { field: "first_name", label: "Prénom" },
  { field: "last_name", label: "Nom" },
  { field: "primary_mail_address", label: "Adresse mail" },
];

const jobFields: FieldType[] = [
  { field: "first_day_date", label: "Premier jour Ouihelp" },
  { field: "last_day_date", label: "Dernier jour Ouihelp" },
  { field: "job", label: "Poste actuel" },
  { field: "job", label: "Territoire" },
  { field: "job", label: "Premier jour poste actuel" },
  { field: "job", label: "Dernier jour poste actuel" },
];

const idsFields: FieldType[] = [
  { field: "uuid", label: "ID DSIT" },
  { field: "microsoft_id", label: "ID Microsoft" },
  { field: "google_id", label: "ID Google" },
  { field: "slack_id", label: "ID Slack" },
  { field: "aircall_id", label: "ID Aircall" },
];

const CustomDataList = ({ fields, data }: { fields: FieldType[]; data: HohishType }) => (
  <DataListRoot gap={2} variant={"bold"}>
    {fields.map(({ field, label }) => (
      <DataListItem key={field} label={label} value={data[field]} />
    ))}
  </DataListRoot>
);

export const ViewHohish = () => {
  const { hohishUuid } = useParams();
  if (!hohishUuid) {
    throw new Error("No hohishUuid provided");
  }

  const { data } = useGetHohishHohishesHohishUuidGet(
    { path: { hohish_uuid: hohishUuid } },
    ["uncached"],
    {}
  );
  if (!data) {
    return null;
  }

  return (
    <Stack>
      <Box p={8} backgroundColor={"gray.100"}>
        <Heading size="2xl">
          {data.first_name} {data.last_name}
        </Heading>
      </Box>

      <Box p={8}>
        <SimpleGrid columns={2} gap={8} minChildWidth={"sm"}>
          <Stack gap={2}>
            <Heading size="md">Identité</Heading>

            <CustomDataList fields={identityFields} data={data} />
          </Stack>

          <Stack gap={2}>
            <Heading size="md">Contrat et affectation</Heading>

            <CustomDataList fields={jobFields} data={data} />
          </Stack>

          <Stack gap={2}>
            <Heading size="md">Identifiants</Heading>

            <CustomDataList fields={idsFields} data={data} />
          </Stack>
        </SimpleGrid>
      </Box>
    </Stack>
  );
};
