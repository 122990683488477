import { Link, Heading, Stack, Box, Flex, createListCollection } from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";

import { useGetHohishesHohishesGet, useGetJobsHohishesJobsGet } from "@/codegen/openapi/queries";
import { HohishType } from "@/codegen/openapi/requests/types.gen";
import { LegalEntityName } from "@/components/2/legal-entity";
import { TerritoryName } from "@/components/2/geo";
import { useContext, useState } from "react";
import { LegalEntityContext } from "@/components/2/legal-entity-context";
import { TableLayout } from "@/components/2/layouts/table";
import {
  SelectContent,
  SelectItem,
  SelectLabel,
  SelectRoot,
  SelectTrigger,
  SelectValueText,
} from "@/codegen/ui/select";

const columnHelper = createColumnHelper<HohishType>();

const JobName = ({ job }: { job: string | null }) => {
  const { data } = useGetJobsHohishesJobsGet({}, [], {
    staleTime: 12 * 60 * 60 * 1000, // 12 hours
  });
  const foundJob = data?.find(j => j.value === job);
  if (!foundJob) {
    return null;
  }
  return <span>{foundJob.label}</span>;
};

const columns = [
  columnHelper.accessor("legal_entity_uuid", {
    header: "Entité",
    cell: ({ cell }) => <LegalEntityName legalEntityUuid={cell.getValue()} />,
    minSize: 150,
  }),
  columnHelper.accessor(row => `${row.first_name} ${row.last_name}`, {
    id: "full_name",
    header: "Prénom & Nom",
    cell: ({ cell, row }) => (
      <Link variant="underline" colorPalette={"blue"} href={`/2/hohishes/${row.original.uuid}`}>
        {cell.getValue()}
      </Link>
    ),
    minSize: 250,
  }),
  columnHelper.accessor("job", {
    header: "Poste",
    cell: ({ cell }) => <JobName job={cell.getValue()} />,
    minSize: 280,
  }),
  columnHelper.accessor("agency_uuids", {
    header: "Territoire",
    cell: ({ cell }) => {
      const territoriesJSX = (cell.getValue() || []).map(v => (
        <TerritoryName key={v} territoryUuid={v} />
      ));
      return territoriesJSX.flatMap((e, i) => [e, <span key={i}>, </span>]).slice(0, -1);
    },
    enableSorting: false,
  }),
  columnHelper.accessor("first_day_date", { header: "Premier jour", minSize: 120, size: 120 }),
];

const hohishStatusesCollection = createListCollection({
  items: [
    { label: "Tous les collaborateurs", value: "", realValue: undefined },
    { label: "Collaborateurs actuels", value: "false", realValue: false },
    { label: "Collaborateurs partis", value: "true", realValue: true },
  ],
});
const hohishStatusesCollectionMap = Object.fromEntries(
  hohishStatusesCollection.items.map(e => [e.value, e.realValue])
);

export const Hohishes = () => {
  const legalEntityContext = useContext(LegalEntityContext);
  const [hohishStatuses, setHohishStatuses] = useState([""]);

  const { data } = useGetHohishesHohishesGet(
    { query: { legal_entity_uuids: legalEntityContext ? [legalEntityContext] : null } },
    ["uncached", legalEntityContext || "all"],
    { placeholderData: [] }
  );

  return (
    <Stack>
      <Box p={8} backgroundColor={"gray.100"}>
        <Heading size="2xl">Collaborateurs</Heading>
      </Box>

      <Box p={8}>
        <Stack gap={4}>
          <Flex justify="space-between" gap={4}>
            <SelectRoot
              collection={hohishStatusesCollection}
              value={hohishStatuses}
              onValueChange={e => setHohishStatuses(e.value)}
              maxW={280}
            >
              <SelectLabel />
              <SelectTrigger>
                <SelectValueText />
              </SelectTrigger>
              <SelectContent>
                {hohishStatusesCollection.items.map(e => (
                  <SelectItem item={e} key={e.value}>
                    {e.label}
                  </SelectItem>
                ))}
              </SelectContent>
            </SelectRoot>
          </Flex>

          <TableLayout
            data={(data as HohishType[]).filter(
              h =>
                hohishStatusesCollectionMap[hohishStatuses[0]] === undefined ||
                h.gone === hohishStatusesCollectionMap[hohishStatuses[0]]
            )}
            columns={columns}
            withPagination
          />
        </Stack>
      </Box>
    </Stack>
  );
};
