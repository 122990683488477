import { Box, Flex, Stack, HStack, Icon, Input, Button, Group, Text } from "@chakra-ui/react";
import { InputGroup } from "../../codegen/ui/input-group";
import { ReactNode } from "react";

import { FiSearch } from "react-icons/fi";

export const LayoutTable = ({
  title,
  withSearch,
  actions,
  table,
  withPagination,
}: {
  title: string;
  withSearch?: boolean;
  actions?: ReactNode;
  table: ReactNode;
  withPagination?: boolean;
}) => {
  return (
    <Flex direction={"column"} maxHeight={"100%"} gap={"4"}>
      <Box px="2" py="2">
        <Stack direction={{ base: "column", md: "row" }} justify="space-between">
          <Text textStyle="lg" fontWeight="medium">
            {title}
          </Text>

          <HStack>
            {withSearch ? (
              <InputGroup
                maxW="xs"
                startElement={<Icon as={FiSearch} color="fg.muted" boxSize="5" />}
              >
                <Input placeholder="Search" />
              </InputGroup>
            ) : null}

            {actions ? actions : null}
          </HStack>
        </Stack>
      </Box>

      <Box flex={1} overflowY={"scroll"}>
        {table}
      </Box>

      {withPagination ? (
        <Box px={{ base: "4", md: "6" }} pb="5">
          <HStack gap="3" justify="space-between">
            <Text color="fg.muted" textStyle="sm">
              Showing 1 to 5 of 42 results
            </Text>
            <Group gap="3" justifyContent="space-between" width={{ base: "full", md: "auto" }}>
              <Button variant="subtle">Previous</Button>
              <Button variant="subtle">Next</Button>
            </Group>
          </HStack>
        </Box>
      ) : null}
    </Flex>
  );
};
