import { ReactNode } from "react";

import { FieldError, Merge } from "react-hook-form";

import { Field } from "../../../codegen/ui/field";

export const FormStepCustom = ({
  label,
  input,
  error,
  helpText,
}: {
  label: string;
  input: ReactNode;
  error: Merge<FieldError, (FieldError | undefined)[]> | undefined;
  helpText?: ReactNode;
}) => (
  <Field
    invalid={!!error}
    label={label}
    errorText={error && error.message ? error.message : null}
    helperText={helpText}
  >
    {input}
  </Field>
);
