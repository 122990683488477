import "typeface-roboto-mono";

import { useEffect } from "react";

import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate } from "@azure/msal-react";

import { Shell as Shell2, ShellWithSidebar as Shell2WithSidebar } from "./components/2/Shell";
import {
  AgencyType,
  AppConfig,
  JobType,
  staticDataPromiseResolver,
  ZoneType,
} from "./components/app-config";
import { Shell } from "./components/Shell";

import { improvedFetch, improvedFetch2 } from "./components/fetch";
import { Microsoft } from "./subapps/2/microsoft";
import { Hohishes } from "./subapps/2/hohishes";
import { Onboarding } from "./subapps/2/onboarding";
import { ViewHohish } from "./subapps/2/hohishes/view";
import { FinancialHelpTable } from "./subapps/financial-help/financial-help-table";
import { CreateFinancialHelp } from "./subapps/financial-help/create-financial-help";
import { Regions } from "./subapps/2/geographies/regions";
import { MutualizationAreas } from "./subapps/2/geographies/mutualization-areas";
import { Agencies } from "./subapps/2/geographies/agencies";
import { WaitingForNigol } from "./subapps/loginish";
import { PermerSubapp } from "./subapps/permer";
import { PostalCodesSubapp } from "./subapps/postal-codes";
import { UsersSubapp } from "./subapps/users";
import { ZonesAndAgenciesSubapp } from "./subapps/zones-and-agencies";
import { OUIHELP_LEGAL_ENTITY_UUID } from "./components/known-legal-entity-uuids";

const router = createBrowserRouter([
  {
    path: "/2",
    element: <Shell2 />,
    children: [
      {
        path: "",
        element: <Shell2WithSidebar />,
        children: [
          {
            path: "hohishes",
            element: <Hohishes />,
          },
          {
            path: "hohishes/:hohishUuid",
            element: <ViewHohish />,
          },
          {
            path: "geographies/regions",
            element: <Regions />,
          },
          {
            path: "geographies/mutualization-areas",
            element: <MutualizationAreas />,
          },
          {
            path: "geographies/agencies",
            element: <Agencies />,
          },
          {
            path: "dsi/microsoft",
            element: <Microsoft />,
          },
        ],
      },
      {
        path: "onboarding",
        element: <Onboarding />,
      },
    ],
  },
  {
    path: "/",
    element: <Shell />,
    children: [
      {
        path: "users",
        element: <UsersSubapp />,
      },
      {
        path: "zones-and-agencies",
        element: <ZonesAndAgenciesSubapp />,
      },
      {
        path: "financial-helps",
        element: <FinancialHelpTable />,
      },
      {
        path: "financial-helps/create",
        element: <CreateFinancialHelp />,
      },
      {
        path: "postal-codes",
        element: <PostalCodesSubapp />,
      },
      {
        path: "permer",
        element: <PermerSubapp />,
      },
    ],
  },
]);

const AppInner = () => {
  useEffect(() => {
    const jobsPromise = improvedFetch(`/static-jobs`).then(async jobsResponse => {
      const jobs: JobType[] = await jobsResponse.json();
      return jobs;
    });

    const zonesPromise = improvedFetch2(
      `/2/geographies/zones?legal_entity_uuids=${OUIHELP_LEGAL_ENTITY_UUID}`
    ).then(async zonesResponse => {
      const zones: ZoneType[] = await zonesResponse.json();
      return zones;
    });

    const agenciesPromise = improvedFetch2(
      `/2/geographies/agencies?legal_entity_uuids=${OUIHELP_LEGAL_ENTITY_UUID}`
    ).then(async agenciesResponse => {
      const agencies: AgencyType[] = await agenciesResponse.json();
      return agencies;
    });

    Promise.all([jobsPromise, zonesPromise, agenciesPromise]).then(([jobs, zones, agencies]) => {
      AppConfig.jobs = jobs;
      AppConfig.zones = zones;
      AppConfig.agencies = agencies;
      AppConfig.agenciesOptions = agencies.map(a => ({
        value: a.agency_uuid,
        label: a.agency_name,
        assignable: true,
      }));
      staticDataPromiseResolver();
    });
  }, []);

  return <RouterProvider router={router} />;
};

export const AppRoot = () => (
  <MsalProvider instance={AppConfig.publicClientApplication}>
    <UnauthenticatedTemplate>
      <WaitingForNigol />
    </UnauthenticatedTemplate>
    <AuthenticatedTemplate>
      <AppInner />
    </AuthenticatedTemplate>
  </MsalProvider>
);
