// generated with @7nohe/openapi-react-query-codegen@2.0.0-beta.3

import { type Options } from "@hey-api/client-fetch";
import { UseQueryResult } from "@tanstack/react-query";
import {
  boomBoomGet,
  deleteAgencyGeographiesAgenciesAgencyUuidDelete,
  deleteZoneGeographiesZonesZoneSlugDelete,
  getAgenciesGeographiesAgenciesGet,
  getAgenciesPostalCodesBindingsGeographiesAgenciesPostalCodesBindingsGet,
  getDepartmentsGeographiesDepartmentsGet,
  getHohishesHohishesGet,
  getHohishHohishesHohishUuidGet,
  getJobsHohishesJobsGet,
  getLegalEntityLegalEntitiesGet,
  getMutualizationAreasGeographiesMutualizationAreasGet,
  getPostalCodesGeographiesPostalCodesGet,
  getPostalCodesWithAgenciesGeographiesPostalCodesWithAgenciesGet,
  getRegionsGeographiesRegionsGet,
  getZonesGeographiesZonesGet,
  patchHohishHohishesHohishUuidPatch,
  postAgencyGeographiesAgenciesPost,
  postZoneGeographiesZonesPost,
  putAgencyGeographiesAgenciesAgencyUuidPut,
  putZoneGeographiesZonesZoneSlugPut,
  statusStatusGet,
} from "../requests/services.gen";
export type GetDepartmentsGeographiesDepartmentsGetDefaultResponse = Awaited<
  ReturnType<typeof getDepartmentsGeographiesDepartmentsGet>
>["data"];
export type GetDepartmentsGeographiesDepartmentsGetQueryResult<
  TData = GetDepartmentsGeographiesDepartmentsGetDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useGetDepartmentsGeographiesDepartmentsGetKey =
  "GetDepartmentsGeographiesDepartmentsGet";
export const UseGetDepartmentsGeographiesDepartmentsGetKeyFn = (
  clientOptions: Options<unknown, true> = {},
  queryKey?: Array<unknown>
) => [useGetDepartmentsGeographiesDepartmentsGetKey, ...(queryKey ?? [clientOptions])];
export type GetPostalCodesWithAgenciesGeographiesPostalCodesWithAgenciesGetDefaultResponse =
  Awaited<
    ReturnType<typeof getPostalCodesWithAgenciesGeographiesPostalCodesWithAgenciesGet>
  >["data"];
export type GetPostalCodesWithAgenciesGeographiesPostalCodesWithAgenciesGetQueryResult<
  TData = GetPostalCodesWithAgenciesGeographiesPostalCodesWithAgenciesGetDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useGetPostalCodesWithAgenciesGeographiesPostalCodesWithAgenciesGetKey =
  "GetPostalCodesWithAgenciesGeographiesPostalCodesWithAgenciesGet";
export const UseGetPostalCodesWithAgenciesGeographiesPostalCodesWithAgenciesGetKeyFn = (
  clientOptions: Options<unknown, true> = {},
  queryKey?: Array<unknown>
) => [
  useGetPostalCodesWithAgenciesGeographiesPostalCodesWithAgenciesGetKey,
  ...(queryKey ?? [clientOptions]),
];
export type GetAgenciesPostalCodesBindingsGeographiesAgenciesPostalCodesBindingsGetDefaultResponse =
  Awaited<
    ReturnType<typeof getAgenciesPostalCodesBindingsGeographiesAgenciesPostalCodesBindingsGet>
  >["data"];
export type GetAgenciesPostalCodesBindingsGeographiesAgenciesPostalCodesBindingsGetQueryResult<
  TData = GetAgenciesPostalCodesBindingsGeographiesAgenciesPostalCodesBindingsGetDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useGetAgenciesPostalCodesBindingsGeographiesAgenciesPostalCodesBindingsGetKey =
  "GetAgenciesPostalCodesBindingsGeographiesAgenciesPostalCodesBindingsGet";
export const UseGetAgenciesPostalCodesBindingsGeographiesAgenciesPostalCodesBindingsGetKeyFn = (
  clientOptions: Options<unknown, true> = {},
  queryKey?: Array<unknown>
) => [
  useGetAgenciesPostalCodesBindingsGeographiesAgenciesPostalCodesBindingsGetKey,
  ...(queryKey ?? [clientOptions]),
];
export type GetPostalCodesGeographiesPostalCodesGetDefaultResponse = Awaited<
  ReturnType<typeof getPostalCodesGeographiesPostalCodesGet>
>["data"];
export type GetPostalCodesGeographiesPostalCodesGetQueryResult<
  TData = GetPostalCodesGeographiesPostalCodesGetDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useGetPostalCodesGeographiesPostalCodesGetKey =
  "GetPostalCodesGeographiesPostalCodesGet";
export const UseGetPostalCodesGeographiesPostalCodesGetKeyFn = (
  clientOptions: Options<unknown, true> = {},
  queryKey?: Array<unknown>
) => [useGetPostalCodesGeographiesPostalCodesGetKey, ...(queryKey ?? [clientOptions])];
export type GetRegionsGeographiesRegionsGetDefaultResponse = Awaited<
  ReturnType<typeof getRegionsGeographiesRegionsGet>
>["data"];
export type GetRegionsGeographiesRegionsGetQueryResult<
  TData = GetRegionsGeographiesRegionsGetDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useGetRegionsGeographiesRegionsGetKey = "GetRegionsGeographiesRegionsGet";
export const UseGetRegionsGeographiesRegionsGetKeyFn = (
  clientOptions: Options<unknown, true> = {},
  queryKey?: Array<unknown>
) => [useGetRegionsGeographiesRegionsGetKey, ...(queryKey ?? [clientOptions])];
export type GetMutualizationAreasGeographiesMutualizationAreasGetDefaultResponse = Awaited<
  ReturnType<typeof getMutualizationAreasGeographiesMutualizationAreasGet>
>["data"];
export type GetMutualizationAreasGeographiesMutualizationAreasGetQueryResult<
  TData = GetMutualizationAreasGeographiesMutualizationAreasGetDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useGetMutualizationAreasGeographiesMutualizationAreasGetKey =
  "GetMutualizationAreasGeographiesMutualizationAreasGet";
export const UseGetMutualizationAreasGeographiesMutualizationAreasGetKeyFn = (
  clientOptions: Options<unknown, true> = {},
  queryKey?: Array<unknown>
) => [
  useGetMutualizationAreasGeographiesMutualizationAreasGetKey,
  ...(queryKey ?? [clientOptions]),
];
export type GetAgenciesGeographiesAgenciesGetDefaultResponse = Awaited<
  ReturnType<typeof getAgenciesGeographiesAgenciesGet>
>["data"];
export type GetAgenciesGeographiesAgenciesGetQueryResult<
  TData = GetAgenciesGeographiesAgenciesGetDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useGetAgenciesGeographiesAgenciesGetKey = "GetAgenciesGeographiesAgenciesGet";
export const UseGetAgenciesGeographiesAgenciesGetKeyFn = (
  clientOptions: Options<unknown, true> = {},
  queryKey?: Array<unknown>
) => [useGetAgenciesGeographiesAgenciesGetKey, ...(queryKey ?? [clientOptions])];
export type GetZonesGeographiesZonesGetDefaultResponse = Awaited<
  ReturnType<typeof getZonesGeographiesZonesGet>
>["data"];
export type GetZonesGeographiesZonesGetQueryResult<
  TData = GetZonesGeographiesZonesGetDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useGetZonesGeographiesZonesGetKey = "GetZonesGeographiesZonesGet";
export const UseGetZonesGeographiesZonesGetKeyFn = (
  clientOptions: Options<unknown, true> = {},
  queryKey?: Array<unknown>
) => [useGetZonesGeographiesZonesGetKey, ...(queryKey ?? [clientOptions])];
export type GetJobsHohishesJobsGetDefaultResponse = Awaited<
  ReturnType<typeof getJobsHohishesJobsGet>
>["data"];
export type GetJobsHohishesJobsGetQueryResult<
  TData = GetJobsHohishesJobsGetDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useGetJobsHohishesJobsGetKey = "GetJobsHohishesJobsGet";
export const UseGetJobsHohishesJobsGetKeyFn = (
  clientOptions: Options<unknown, true> = {},
  queryKey?: Array<unknown>
) => [useGetJobsHohishesJobsGetKey, ...(queryKey ?? [clientOptions])];
export type GetHohishesHohishesGetDefaultResponse = Awaited<
  ReturnType<typeof getHohishesHohishesGet>
>["data"];
export type GetHohishesHohishesGetQueryResult<
  TData = GetHohishesHohishesGetDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useGetHohishesHohishesGetKey = "GetHohishesHohishesGet";
export const UseGetHohishesHohishesGetKeyFn = (
  clientOptions: Options<unknown, true> = {},
  queryKey?: Array<unknown>
) => [useGetHohishesHohishesGetKey, ...(queryKey ?? [clientOptions])];
export type GetHohishHohishesHohishUuidGetDefaultResponse = Awaited<
  ReturnType<typeof getHohishHohishesHohishUuidGet>
>["data"];
export type GetHohishHohishesHohishUuidGetQueryResult<
  TData = GetHohishHohishesHohishUuidGetDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useGetHohishHohishesHohishUuidGetKey = "GetHohishHohishesHohishUuidGet";
export const UseGetHohishHohishesHohishUuidGetKeyFn = (
  clientOptions: Options<unknown, true>,
  queryKey?: Array<unknown>
) => [useGetHohishHohishesHohishUuidGetKey, ...(queryKey ?? [clientOptions])];
export type GetLegalEntityLegalEntitiesGetDefaultResponse = Awaited<
  ReturnType<typeof getLegalEntityLegalEntitiesGet>
>["data"];
export type GetLegalEntityLegalEntitiesGetQueryResult<
  TData = GetLegalEntityLegalEntitiesGetDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useGetLegalEntityLegalEntitiesGetKey = "GetLegalEntityLegalEntitiesGet";
export const UseGetLegalEntityLegalEntitiesGetKeyFn = (
  clientOptions: Options<unknown, true> = {},
  queryKey?: Array<unknown>
) => [useGetLegalEntityLegalEntitiesGetKey, ...(queryKey ?? [clientOptions])];
export type StatusStatusGetDefaultResponse = Awaited<ReturnType<typeof statusStatusGet>>["data"];
export type StatusStatusGetQueryResult<
  TData = StatusStatusGetDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useStatusStatusGetKey = "StatusStatusGet";
export const UseStatusStatusGetKeyFn = (
  clientOptions: Options<unknown, true> = {},
  queryKey?: Array<unknown>
) => [useStatusStatusGetKey, ...(queryKey ?? [clientOptions])];
export type BoomBoomGetDefaultResponse = Awaited<ReturnType<typeof boomBoomGet>>["data"];
export type BoomBoomGetQueryResult<
  TData = BoomBoomGetDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useBoomBoomGetKey = "BoomBoomGet";
export const UseBoomBoomGetKeyFn = (
  clientOptions: Options<unknown, true> = {},
  queryKey?: Array<unknown>
) => [useBoomBoomGetKey, ...(queryKey ?? [clientOptions])];
export type PostAgencyGeographiesAgenciesPostMutationResult = Awaited<
  ReturnType<typeof postAgencyGeographiesAgenciesPost>
>;
export const usePostAgencyGeographiesAgenciesPostKey = "PostAgencyGeographiesAgenciesPost";
export const UsePostAgencyGeographiesAgenciesPostKeyFn = (mutationKey?: Array<unknown>) => [
  usePostAgencyGeographiesAgenciesPostKey,
  ...(mutationKey ?? []),
];
export type PostZoneGeographiesZonesPostMutationResult = Awaited<
  ReturnType<typeof postZoneGeographiesZonesPost>
>;
export const usePostZoneGeographiesZonesPostKey = "PostZoneGeographiesZonesPost";
export const UsePostZoneGeographiesZonesPostKeyFn = (mutationKey?: Array<unknown>) => [
  usePostZoneGeographiesZonesPostKey,
  ...(mutationKey ?? []),
];
export type PutAgencyGeographiesAgenciesAgencyUuidPutMutationResult = Awaited<
  ReturnType<typeof putAgencyGeographiesAgenciesAgencyUuidPut>
>;
export const usePutAgencyGeographiesAgenciesAgencyUuidPutKey =
  "PutAgencyGeographiesAgenciesAgencyUuidPut";
export const UsePutAgencyGeographiesAgenciesAgencyUuidPutKeyFn = (mutationKey?: Array<unknown>) => [
  usePutAgencyGeographiesAgenciesAgencyUuidPutKey,
  ...(mutationKey ?? []),
];
export type PutZoneGeographiesZonesZoneSlugPutMutationResult = Awaited<
  ReturnType<typeof putZoneGeographiesZonesZoneSlugPut>
>;
export const usePutZoneGeographiesZonesZoneSlugPutKey = "PutZoneGeographiesZonesZoneSlugPut";
export const UsePutZoneGeographiesZonesZoneSlugPutKeyFn = (mutationKey?: Array<unknown>) => [
  usePutZoneGeographiesZonesZoneSlugPutKey,
  ...(mutationKey ?? []),
];
export type PatchHohishHohishesHohishUuidPatchMutationResult = Awaited<
  ReturnType<typeof patchHohishHohishesHohishUuidPatch>
>;
export const usePatchHohishHohishesHohishUuidPatchKey = "PatchHohishHohishesHohishUuidPatch";
export const UsePatchHohishHohishesHohishUuidPatchKeyFn = (mutationKey?: Array<unknown>) => [
  usePatchHohishHohishesHohishUuidPatchKey,
  ...(mutationKey ?? []),
];
export type DeleteAgencyGeographiesAgenciesAgencyUuidDeleteMutationResult = Awaited<
  ReturnType<typeof deleteAgencyGeographiesAgenciesAgencyUuidDelete>
>;
export const useDeleteAgencyGeographiesAgenciesAgencyUuidDeleteKey =
  "DeleteAgencyGeographiesAgenciesAgencyUuidDelete";
export const UseDeleteAgencyGeographiesAgenciesAgencyUuidDeleteKeyFn = (
  mutationKey?: Array<unknown>
) => [useDeleteAgencyGeographiesAgenciesAgencyUuidDeleteKey, ...(mutationKey ?? [])];
export type DeleteZoneGeographiesZonesZoneSlugDeleteMutationResult = Awaited<
  ReturnType<typeof deleteZoneGeographiesZonesZoneSlugDelete>
>;
export const useDeleteZoneGeographiesZonesZoneSlugDeleteKey =
  "DeleteZoneGeographiesZonesZoneSlugDelete";
export const UseDeleteZoneGeographiesZonesZoneSlugDeleteKeyFn = (mutationKey?: Array<unknown>) => [
  useDeleteZoneGeographiesZonesZoneSlugDeleteKey,
  ...(mutationKey ?? []),
];
