import { useGetLegalEntityLegalEntitiesGet } from "@/codegen/openapi/queries";

export const LegalEntityName = ({ legalEntityUuid }: { legalEntityUuid: string }) => {
  const { data } = useGetLegalEntityLegalEntitiesGet({}, [], {
    staleTime: 12 * 60 * 60 * 1000, // 12 hours
  });
  const legalEntity = data?.find(le => le.uuid === legalEntityUuid);
  if (!legalEntity) {
    return null;
  }
  return <span>{legalEntity.name}</span>;
};
