import { ReactNode, ReactElement, useState } from "react";
import { Provider as ChakraProvider } from "../../codegen/ui/provider";
import { QueryClient } from "@tanstack/react-query";
import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client";
import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";
import { Box, Flex, Stack, Text, HStack, Grid, createListCollection } from "@chakra-ui/react";
import { BiCreditCard, BiRecycle, BiLogOut, BiBody } from "react-icons/bi";
import { BsCaretRightFill } from "react-icons/bs";
import { Outlet, useMatch, useNavigate } from "react-router-dom";
import "@fontsource-variable/open-sans";
import "@fontsource-variable/spline-sans";
import { AppConfig } from "../app-config";
import { PiDotsThree, PiDotsSix, PiDotsNine } from "react-icons/pi";

import {
  SelectContent,
  SelectItem,
  SelectLabel,
  SelectRoot,
  SelectTrigger,
  SelectValueText,
} from "@/codegen/ui/select";
import { LegalEntityContext } from "./legal-entity-context";
import { OUIHELP_LEGAL_ENTITY_UUID, JOYA_LEGAL_ENTITY_UUID } from "../known-legal-entity-uuids";

const entities = createListCollection({
  items: [
    { label: "Groupe Ouihelp", value: "" },
    { label: "Ouihelp", value: OUIHELP_LEGAL_ENTITY_UUID },
    { label: "Joya", value: JOYA_LEGAL_ENTITY_UUID },
  ],
});

interface NavGroupProps {
  label: string;
  children: ReactNode;
}

interface NavItemProps {
  href?: string;
  label: string;
  subtle?: boolean;
  active?: boolean;
  icon: ReactElement;
  endElement?: ReactElement;
  children?: ReactNode;
  linkTo?: string;
  onClick?: () => void;
}

const NavItem = (props: NavItemProps) => {
  const navigate = useNavigate();
  const { active, subtle, icon, children, label, endElement, href, linkTo, onClick } = props;
  const match = useMatch(linkTo || "PROBABLY_NOT");
  const refinedActive = active || match;
  return (
    <HStack
      asChild
      w="full"
      px="3"
      py="2"
      cursor="pointer"
      userSelect="none"
      rounded="md"
      transition="all 0.2s"
      bg={refinedActive ? "gray.700" : undefined}
      _hover={{ bg: "gray.700" }}
      _active={{ bg: "gray.600" }}
      onClick={
        onClick
          ? onClick
          : () => {
              if (linkTo) {
                navigate(linkTo);
              }
            }
      }
    >
      <a href={href}>
        <Box fontSize="lg" color={refinedActive ? "currentcolor" : "gray.400"}>
          {icon}
        </Box>
        <Box flex="1" fontWeight="inherit" color={subtle ? "gray.400" : undefined}>
          {label}
        </Box>
        {endElement && !children && <Box>{endElement}</Box>}
        {children && <Box fontSize="xs" flexShrink={0} as={BsCaretRightFill} />}
      </a>
    </HStack>
  );
};

const NavGroup = (props: NavGroupProps) => {
  const { label, children } = props;
  return (
    <Box>
      <Text
        px="3"
        fontSize="xs"
        fontWeight="semibold"
        textTransform="uppercase"
        letterSpacing="widest"
        color="gray.500"
        mb="3"
      >
        {label}
      </Text>
      <Stack gap="1">{children}</Stack>
    </Box>
  );
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      gcTime: 1000 * 60 * 60 * 24,
    },
  },
});

const persister = createSyncStoragePersister({
  storage: window.localStorage,
});

export const OuterShell: React.FC<React.PropsWithChildren> = ({
  children,
}: React.PropsWithChildren) => <ChakraProvider enableSystem={false}>{children}</ChakraProvider>;

export const Shell = () => {
  return (
    <OuterShell>
      <PersistQueryClientProvider
        client={queryClient}
        persistOptions={{
          persister,
          dehydrateOptions: {
            shouldDehydrateQuery: query => !query.queryKey.includes("uncached"),
          },
        }}
      >
        <Outlet />
      </PersistQueryClientProvider>
    </OuterShell>
  );
};

export const ShellWithSidebar = () => {
  const [legalEntityContext, setLegalEntityContext] = useState([""]);

  // Wow, much secure.
  const isDSI =
    AppConfig.publicClientApplication.getActiveAccount()?.username === "bastien@ouihelp.fr";

  return (
    <Grid
      width="100vw"
      maxWidth="100%"
      minHeight="100vh"
      gridTemplateColumns={"256px 1fr"}
      gridTemplateRows={"1fr"}
    >
      <Box w="64" bg="gray.900" color="white">
        <Flex h="full" direction="column" px="4" py="4">
          <Stack gap="8" flex="1" overflow="auto" pt="8">
            <NavGroup label="Filtre entité">
              <SelectRoot
                collection={entities}
                value={legalEntityContext}
                onValueChange={e => setLegalEntityContext(e.value)}
              >
                <SelectLabel />
                <SelectTrigger>
                  <SelectValueText />
                </SelectTrigger>
                <SelectContent>
                  {entities.items.map(e => (
                    <SelectItem item={e} key={e.value}>
                      {e.label}
                    </SelectItem>
                  ))}
                </SelectContent>
              </SelectRoot>
            </NavGroup>

            <NavGroup label="Collaborateurs">
              <NavItem icon={<BiBody />} label="Collaborateurs" linkTo="/2/hohishes" />
            </NavGroup>

            <NavGroup label="Géographies">
              <NavItem icon={<PiDotsNine />} label="Régions" linkTo="/2/geographies/regions" />
              <NavItem
                icon={<PiDotsSix />}
                label="Mutualisations"
                linkTo="/2/geographies/mutualization-areas"
              />
              <NavItem icon={<PiDotsThree />} label="Agences" linkTo="/2/geographies/agencies" />
            </NavGroup>

            {isDSI ? (
              <NavGroup label="DSI">
                <NavItem icon={<BiCreditCard />} label="Microsoft" linkTo="/2/dsi/microsoft" />
                <NavItem icon={<BiRecycle />} label="Resync" linkTo="/2/dsi/resync" />
                <NavItem
                  icon={<BiRecycle />}
                  label="Trigger Sentry"
                  // @ts-expect-error: Intentional to trigger Sentry.
                  onClick={() => iDoNotExist()}
                />
              </NavGroup>
            ) : null}
          </Stack>

          <Box>
            <NavItem subtle icon={<BiLogOut />} label="Se déconnecter" onClick={AppConfig.logout} />
          </Box>
        </Flex>
      </Box>

      <Box width={"100%"}>
        <LegalEntityContext.Provider value={legalEntityContext[0]}>
          <Outlet />
        </LegalEntityContext.Provider>
      </Box>
    </Grid>
  );
};
