import { Button } from "@/codegen/ui/button";
import {
  PaginationRoot,
  PaginationItems,
  PaginationPrevTrigger,
  PaginationNextTrigger,
} from "@/codegen/ui/pagination";
import { Stack, HStack, Table, Flex } from "@chakra-ui/react";
import {
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
  ColumnDef,
  getSortedRowModel,
  ColumnFiltersState,
} from "@tanstack/react-table";

export const TableLayout = function <T>({
  data,
  columns,
  withPagination,
  withSorting = true,
  columnFilters,
}: {
  data: T[];
  columns: ColumnDef<T, any>[];
  withPagination?: boolean;
  withSorting?: boolean;
  columnFilters?: ColumnFiltersState | undefined;
}) {
  const table = useReactTable({
    data: data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    ...(withPagination ? { getPaginationRowModel: getPaginationRowModel() } : {}),
    ...(withSorting ? { getSortedRowModel: getSortedRowModel() } : {}),
    defaultColumn: {
      size: -1,
      minSize: -1,
    },
    state: {
      columnFilters,
    },
    initialState: {
      ...(withPagination
        ? {
            pagination: {
              pageIndex: 0,
              pageSize: 15,
            },
          }
        : {}),
    },
  });

  return (
    <Stack gap={4}>
      <Table.Root
        size="sm"
        // Rounded border styling starts
        rounded="md"
        borderWidth={1}
        borderCollapse={"separate"}
        borderSpacing={0}
        overflow={"hidden"}
        css={{
          "& tr:last-child td": { borderBottom: 0 },
        }}
        // Rounded border styling ends
      >
        <Table.Header>
          {table.getHeaderGroups().map(headerGroup => (
            <Table.Row key={headerGroup.id} backgroundColor={"gray.100"}>
              {headerGroup.headers.map(header => (
                <Table.ColumnHeader
                  key={header.id}
                  style={{ width: header.getSize() }}
                  onClick={header.column.getToggleSortingHandler()}
                >
                  {header.isPlaceholder
                    ? null
                    : [
                        flexRender(header.column.columnDef.header, header.getContext()),
                        {
                          asc: " ↓",
                          desc: " ↑",
                        }[header.column.getIsSorted() as string] ?? null,
                      ]}
                </Table.ColumnHeader>
              ))}
            </Table.Row>
          ))}
        </Table.Header>

        <Table.Body>
          {table.getRowModel().rows.map(row => (
            <Table.Row key={row.id}>
              {row.getVisibleCells().map(cell => (
                <Table.Cell key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </Table.Cell>
              ))}
            </Table.Row>
          ))}
        </Table.Body>
      </Table.Root>

      {withPagination && (
        <Flex gap="8" justify="space-between">
          <PaginationRoot
            count={table.getRowCount()}
            pageSize={table.getState().pagination.pageSize}
            page={table.getState().pagination.pageIndex + 1}
            onPageChange={e => table.setPageIndex(e.page - 1)}
          >
            <HStack>
              <PaginationPrevTrigger />
              <PaginationItems />
              <PaginationNextTrigger />
            </HStack>
          </PaginationRoot>

          <HStack>
            {[15, 30, 50].map(size => (
              <Button
                key={size}
                variant={table.getState().pagination.pageSize === size ? "outline" : "ghost"}
                onClick={() => table.setPageSize(size)}
              >
                {size}
              </Button>
            ))}
          </HStack>
        </Flex>
      )}
    </Stack>
  );
};
