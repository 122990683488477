// generated with @7nohe/openapi-react-query-codegen@2.0.0-beta.3

import { type Options } from "@hey-api/client-fetch";
import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from "@tanstack/react-query";
import {
  boomBoomGet,
  deleteAgencyGeographiesAgenciesAgencyUuidDelete,
  deleteZoneGeographiesZonesZoneSlugDelete,
  getAgenciesGeographiesAgenciesGet,
  getAgenciesPostalCodesBindingsGeographiesAgenciesPostalCodesBindingsGet,
  getDepartmentsGeographiesDepartmentsGet,
  getHohishesHohishesGet,
  getHohishHohishesHohishUuidGet,
  getJobsHohishesJobsGet,
  getLegalEntityLegalEntitiesGet,
  getMutualizationAreasGeographiesMutualizationAreasGet,
  getPostalCodesGeographiesPostalCodesGet,
  getPostalCodesWithAgenciesGeographiesPostalCodesWithAgenciesGet,
  getRegionsGeographiesRegionsGet,
  getZonesGeographiesZonesGet,
  patchHohishHohishesHohishUuidPatch,
  postAgencyGeographiesAgenciesPost,
  postZoneGeographiesZonesPost,
  putAgencyGeographiesAgenciesAgencyUuidPut,
  putZoneGeographiesZonesZoneSlugPut,
  statusStatusGet,
} from "../requests/services.gen";
import {
  BoomBoomGetError,
  DeleteAgencyGeographiesAgenciesAgencyUuidDeleteData,
  DeleteAgencyGeographiesAgenciesAgencyUuidDeleteError,
  DeleteZoneGeographiesZonesZoneSlugDeleteData,
  DeleteZoneGeographiesZonesZoneSlugDeleteError,
  GetAgenciesGeographiesAgenciesGetData,
  GetAgenciesGeographiesAgenciesGetError,
  GetAgenciesPostalCodesBindingsGeographiesAgenciesPostalCodesBindingsGetError,
  GetDepartmentsGeographiesDepartmentsGetError,
  GetHohishesHohishesGetData,
  GetHohishesHohishesGetError,
  GetHohishHohishesHohishUuidGetData,
  GetHohishHohishesHohishUuidGetError,
  GetJobsHohishesJobsGetError,
  GetLegalEntityLegalEntitiesGetError,
  GetMutualizationAreasGeographiesMutualizationAreasGetData,
  GetMutualizationAreasGeographiesMutualizationAreasGetError,
  GetPostalCodesGeographiesPostalCodesGetError,
  GetPostalCodesWithAgenciesGeographiesPostalCodesWithAgenciesGetError,
  GetRegionsGeographiesRegionsGetData,
  GetRegionsGeographiesRegionsGetError,
  GetZonesGeographiesZonesGetData,
  GetZonesGeographiesZonesGetError,
  PatchHohishHohishesHohishUuidPatchData,
  PatchHohishHohishesHohishUuidPatchError,
  PostAgencyGeographiesAgenciesPostData,
  PostAgencyGeographiesAgenciesPostError,
  PostZoneGeographiesZonesPostData,
  PostZoneGeographiesZonesPostError,
  PutAgencyGeographiesAgenciesAgencyUuidPutData,
  PutAgencyGeographiesAgenciesAgencyUuidPutError,
  PutZoneGeographiesZonesZoneSlugPutData,
  PutZoneGeographiesZonesZoneSlugPutError,
  StatusStatusGetError,
} from "../requests/types.gen";
import * as Common from "./common";
export const useGetDepartmentsGeographiesDepartmentsGet = <
  TData = Common.GetDepartmentsGeographiesDepartmentsGetDefaultResponse,
  TError = GetDepartmentsGeographiesDepartmentsGetError,
  TQueryKey extends Array<unknown> = unknown[],
>(
  clientOptions: Options<unknown, true> = {},
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseGetDepartmentsGeographiesDepartmentsGetKeyFn(clientOptions, queryKey),
    queryFn: () =>
      getDepartmentsGeographiesDepartmentsGet({ ...clientOptions }).then(
        response => response.data as TData
      ) as TData,
    ...options,
  });
export const useGetPostalCodesWithAgenciesGeographiesPostalCodesWithAgenciesGet = <
  TData = Common.GetPostalCodesWithAgenciesGeographiesPostalCodesWithAgenciesGetDefaultResponse,
  TError = GetPostalCodesWithAgenciesGeographiesPostalCodesWithAgenciesGetError,
  TQueryKey extends Array<unknown> = unknown[],
>(
  clientOptions: Options<unknown, true> = {},
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseGetPostalCodesWithAgenciesGeographiesPostalCodesWithAgenciesGetKeyFn(
      clientOptions,
      queryKey
    ),
    queryFn: () =>
      getPostalCodesWithAgenciesGeographiesPostalCodesWithAgenciesGet({ ...clientOptions }).then(
        response => response.data as TData
      ) as TData,
    ...options,
  });
export const useGetAgenciesPostalCodesBindingsGeographiesAgenciesPostalCodesBindingsGet = <
  TData = Common.GetAgenciesPostalCodesBindingsGeographiesAgenciesPostalCodesBindingsGetDefaultResponse,
  TError = GetAgenciesPostalCodesBindingsGeographiesAgenciesPostalCodesBindingsGetError,
  TQueryKey extends Array<unknown> = unknown[],
>(
  clientOptions: Options<unknown, true> = {},
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">
) =>
  useQuery<TData, TError>({
    queryKey:
      Common.UseGetAgenciesPostalCodesBindingsGeographiesAgenciesPostalCodesBindingsGetKeyFn(
        clientOptions,
        queryKey
      ),
    queryFn: () =>
      getAgenciesPostalCodesBindingsGeographiesAgenciesPostalCodesBindingsGet({
        ...clientOptions,
      }).then(response => response.data as TData) as TData,
    ...options,
  });
export const useGetPostalCodesGeographiesPostalCodesGet = <
  TData = Common.GetPostalCodesGeographiesPostalCodesGetDefaultResponse,
  TError = GetPostalCodesGeographiesPostalCodesGetError,
  TQueryKey extends Array<unknown> = unknown[],
>(
  clientOptions: Options<unknown, true> = {},
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseGetPostalCodesGeographiesPostalCodesGetKeyFn(clientOptions, queryKey),
    queryFn: () =>
      getPostalCodesGeographiesPostalCodesGet({ ...clientOptions }).then(
        response => response.data as TData
      ) as TData,
    ...options,
  });
export const useGetRegionsGeographiesRegionsGet = <
  TData = Common.GetRegionsGeographiesRegionsGetDefaultResponse,
  TError = GetRegionsGeographiesRegionsGetError,
  TQueryKey extends Array<unknown> = unknown[],
>(
  clientOptions: Options<GetRegionsGeographiesRegionsGetData, true> = {},
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseGetRegionsGeographiesRegionsGetKeyFn(clientOptions, queryKey),
    queryFn: () =>
      getRegionsGeographiesRegionsGet({ ...clientOptions }).then(
        response => response.data as TData
      ) as TData,
    ...options,
  });
export const useGetMutualizationAreasGeographiesMutualizationAreasGet = <
  TData = Common.GetMutualizationAreasGeographiesMutualizationAreasGetDefaultResponse,
  TError = GetMutualizationAreasGeographiesMutualizationAreasGetError,
  TQueryKey extends Array<unknown> = unknown[],
>(
  clientOptions: Options<GetMutualizationAreasGeographiesMutualizationAreasGetData, true> = {},
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseGetMutualizationAreasGeographiesMutualizationAreasGetKeyFn(
      clientOptions,
      queryKey
    ),
    queryFn: () =>
      getMutualizationAreasGeographiesMutualizationAreasGet({ ...clientOptions }).then(
        response => response.data as TData
      ) as TData,
    ...options,
  });
export const useGetAgenciesGeographiesAgenciesGet = <
  TData = Common.GetAgenciesGeographiesAgenciesGetDefaultResponse,
  TError = GetAgenciesGeographiesAgenciesGetError,
  TQueryKey extends Array<unknown> = unknown[],
>(
  clientOptions: Options<GetAgenciesGeographiesAgenciesGetData, true> = {},
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseGetAgenciesGeographiesAgenciesGetKeyFn(clientOptions, queryKey),
    queryFn: () =>
      getAgenciesGeographiesAgenciesGet({ ...clientOptions }).then(
        response => response.data as TData
      ) as TData,
    ...options,
  });
export const useGetZonesGeographiesZonesGet = <
  TData = Common.GetZonesGeographiesZonesGetDefaultResponse,
  TError = GetZonesGeographiesZonesGetError,
  TQueryKey extends Array<unknown> = unknown[],
>(
  clientOptions: Options<GetZonesGeographiesZonesGetData, true> = {},
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseGetZonesGeographiesZonesGetKeyFn(clientOptions, queryKey),
    queryFn: () =>
      getZonesGeographiesZonesGet({ ...clientOptions }).then(
        response => response.data as TData
      ) as TData,
    ...options,
  });
export const useGetJobsHohishesJobsGet = <
  TData = Common.GetJobsHohishesJobsGetDefaultResponse,
  TError = GetJobsHohishesJobsGetError,
  TQueryKey extends Array<unknown> = unknown[],
>(
  clientOptions: Options<unknown, true> = {},
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseGetJobsHohishesJobsGetKeyFn(clientOptions, queryKey),
    queryFn: () =>
      getJobsHohishesJobsGet({ ...clientOptions }).then(
        response => response.data as TData
      ) as TData,
    ...options,
  });
export const useGetHohishesHohishesGet = <
  TData = Common.GetHohishesHohishesGetDefaultResponse,
  TError = GetHohishesHohishesGetError,
  TQueryKey extends Array<unknown> = unknown[],
>(
  clientOptions: Options<GetHohishesHohishesGetData, true> = {},
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseGetHohishesHohishesGetKeyFn(clientOptions, queryKey),
    queryFn: () =>
      getHohishesHohishesGet({ ...clientOptions }).then(
        response => response.data as TData
      ) as TData,
    ...options,
  });
export const useGetHohishHohishesHohishUuidGet = <
  TData = Common.GetHohishHohishesHohishUuidGetDefaultResponse,
  TError = GetHohishHohishesHohishUuidGetError,
  TQueryKey extends Array<unknown> = unknown[],
>(
  clientOptions: Options<GetHohishHohishesHohishUuidGetData, true>,
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseGetHohishHohishesHohishUuidGetKeyFn(clientOptions, queryKey),
    queryFn: () =>
      getHohishHohishesHohishUuidGet({ ...clientOptions }).then(
        response => response.data as TData
      ) as TData,
    ...options,
  });
export const useGetLegalEntityLegalEntitiesGet = <
  TData = Common.GetLegalEntityLegalEntitiesGetDefaultResponse,
  TError = GetLegalEntityLegalEntitiesGetError,
  TQueryKey extends Array<unknown> = unknown[],
>(
  clientOptions: Options<unknown, true> = {},
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseGetLegalEntityLegalEntitiesGetKeyFn(clientOptions, queryKey),
    queryFn: () =>
      getLegalEntityLegalEntitiesGet({ ...clientOptions }).then(
        response => response.data as TData
      ) as TData,
    ...options,
  });
export const useStatusStatusGet = <
  TData = Common.StatusStatusGetDefaultResponse,
  TError = StatusStatusGetError,
  TQueryKey extends Array<unknown> = unknown[],
>(
  clientOptions: Options<unknown, true> = {},
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseStatusStatusGetKeyFn(clientOptions, queryKey),
    queryFn: () =>
      statusStatusGet({ ...clientOptions }).then(response => response.data as TData) as TData,
    ...options,
  });
export const useBoomBoomGet = <
  TData = Common.BoomBoomGetDefaultResponse,
  TError = BoomBoomGetError,
  TQueryKey extends Array<unknown> = unknown[],
>(
  clientOptions: Options<unknown, true> = {},
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseBoomBoomGetKeyFn(clientOptions, queryKey),
    queryFn: () =>
      boomBoomGet({ ...clientOptions }).then(response => response.data as TData) as TData,
    ...options,
  });
export const usePostAgencyGeographiesAgenciesPost = <
  TData = Common.PostAgencyGeographiesAgenciesPostMutationResult,
  TError = PostAgencyGeographiesAgenciesPostError,
  TQueryKey extends Array<unknown> = unknown[],
  TContext = unknown,
>(
  mutationKey?: TQueryKey,
  options?: Omit<
    UseMutationOptions<
      TData,
      TError,
      Options<PostAgencyGeographiesAgenciesPostData, true>,
      TContext
    >,
    "mutationKey" | "mutationFn"
  >
) =>
  useMutation<TData, TError, Options<PostAgencyGeographiesAgenciesPostData, true>, TContext>({
    mutationKey: Common.UsePostAgencyGeographiesAgenciesPostKeyFn(mutationKey),
    mutationFn: clientOptions =>
      postAgencyGeographiesAgenciesPost(clientOptions) as unknown as Promise<TData>,
    ...options,
  });
export const usePostZoneGeographiesZonesPost = <
  TData = Common.PostZoneGeographiesZonesPostMutationResult,
  TError = PostZoneGeographiesZonesPostError,
  TQueryKey extends Array<unknown> = unknown[],
  TContext = unknown,
>(
  mutationKey?: TQueryKey,
  options?: Omit<
    UseMutationOptions<TData, TError, Options<PostZoneGeographiesZonesPostData, true>, TContext>,
    "mutationKey" | "mutationFn"
  >
) =>
  useMutation<TData, TError, Options<PostZoneGeographiesZonesPostData, true>, TContext>({
    mutationKey: Common.UsePostZoneGeographiesZonesPostKeyFn(mutationKey),
    mutationFn: clientOptions =>
      postZoneGeographiesZonesPost(clientOptions) as unknown as Promise<TData>,
    ...options,
  });
export const usePutAgencyGeographiesAgenciesAgencyUuidPut = <
  TData = Common.PutAgencyGeographiesAgenciesAgencyUuidPutMutationResult,
  TError = PutAgencyGeographiesAgenciesAgencyUuidPutError,
  TQueryKey extends Array<unknown> = unknown[],
  TContext = unknown,
>(
  mutationKey?: TQueryKey,
  options?: Omit<
    UseMutationOptions<
      TData,
      TError,
      Options<PutAgencyGeographiesAgenciesAgencyUuidPutData, true>,
      TContext
    >,
    "mutationKey" | "mutationFn"
  >
) =>
  useMutation<
    TData,
    TError,
    Options<PutAgencyGeographiesAgenciesAgencyUuidPutData, true>,
    TContext
  >({
    mutationKey: Common.UsePutAgencyGeographiesAgenciesAgencyUuidPutKeyFn(mutationKey),
    mutationFn: clientOptions =>
      putAgencyGeographiesAgenciesAgencyUuidPut(clientOptions) as unknown as Promise<TData>,
    ...options,
  });
export const usePutZoneGeographiesZonesZoneSlugPut = <
  TData = Common.PutZoneGeographiesZonesZoneSlugPutMutationResult,
  TError = PutZoneGeographiesZonesZoneSlugPutError,
  TQueryKey extends Array<unknown> = unknown[],
  TContext = unknown,
>(
  mutationKey?: TQueryKey,
  options?: Omit<
    UseMutationOptions<
      TData,
      TError,
      Options<PutZoneGeographiesZonesZoneSlugPutData, true>,
      TContext
    >,
    "mutationKey" | "mutationFn"
  >
) =>
  useMutation<TData, TError, Options<PutZoneGeographiesZonesZoneSlugPutData, true>, TContext>({
    mutationKey: Common.UsePutZoneGeographiesZonesZoneSlugPutKeyFn(mutationKey),
    mutationFn: clientOptions =>
      putZoneGeographiesZonesZoneSlugPut(clientOptions) as unknown as Promise<TData>,
    ...options,
  });
export const usePatchHohishHohishesHohishUuidPatch = <
  TData = Common.PatchHohishHohishesHohishUuidPatchMutationResult,
  TError = PatchHohishHohishesHohishUuidPatchError,
  TQueryKey extends Array<unknown> = unknown[],
  TContext = unknown,
>(
  mutationKey?: TQueryKey,
  options?: Omit<
    UseMutationOptions<
      TData,
      TError,
      Options<PatchHohishHohishesHohishUuidPatchData, true>,
      TContext
    >,
    "mutationKey" | "mutationFn"
  >
) =>
  useMutation<TData, TError, Options<PatchHohishHohishesHohishUuidPatchData, true>, TContext>({
    mutationKey: Common.UsePatchHohishHohishesHohishUuidPatchKeyFn(mutationKey),
    mutationFn: clientOptions =>
      patchHohishHohishesHohishUuidPatch(clientOptions) as unknown as Promise<TData>,
    ...options,
  });
export const useDeleteAgencyGeographiesAgenciesAgencyUuidDelete = <
  TData = Common.DeleteAgencyGeographiesAgenciesAgencyUuidDeleteMutationResult,
  TError = DeleteAgencyGeographiesAgenciesAgencyUuidDeleteError,
  TQueryKey extends Array<unknown> = unknown[],
  TContext = unknown,
>(
  mutationKey?: TQueryKey,
  options?: Omit<
    UseMutationOptions<
      TData,
      TError,
      Options<DeleteAgencyGeographiesAgenciesAgencyUuidDeleteData, true>,
      TContext
    >,
    "mutationKey" | "mutationFn"
  >
) =>
  useMutation<
    TData,
    TError,
    Options<DeleteAgencyGeographiesAgenciesAgencyUuidDeleteData, true>,
    TContext
  >({
    mutationKey: Common.UseDeleteAgencyGeographiesAgenciesAgencyUuidDeleteKeyFn(mutationKey),
    mutationFn: clientOptions =>
      deleteAgencyGeographiesAgenciesAgencyUuidDelete(clientOptions) as unknown as Promise<TData>,
    ...options,
  });
export const useDeleteZoneGeographiesZonesZoneSlugDelete = <
  TData = Common.DeleteZoneGeographiesZonesZoneSlugDeleteMutationResult,
  TError = DeleteZoneGeographiesZonesZoneSlugDeleteError,
  TQueryKey extends Array<unknown> = unknown[],
  TContext = unknown,
>(
  mutationKey?: TQueryKey,
  options?: Omit<
    UseMutationOptions<
      TData,
      TError,
      Options<DeleteZoneGeographiesZonesZoneSlugDeleteData, true>,
      TContext
    >,
    "mutationKey" | "mutationFn"
  >
) =>
  useMutation<TData, TError, Options<DeleteZoneGeographiesZonesZoneSlugDeleteData, true>, TContext>(
    {
      mutationKey: Common.UseDeleteZoneGeographiesZonesZoneSlugDeleteKeyFn(mutationKey),
      mutationFn: clientOptions =>
        deleteZoneGeographiesZonesZoneSlugDelete(clientOptions) as unknown as Promise<TData>,
      ...options,
    }
  );
