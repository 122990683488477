import { PiDotsThree, PiDotsNine, PiDotsSix } from "react-icons/pi";
import { IconType } from "react-icons/lib";

import {
  useGetAgenciesGeographiesAgenciesGet,
  useGetMutualizationAreasGeographiesMutualizationAreasGet,
  useGetRegionsGeographiesRegionsGet,
} from "@/codegen/openapi/queries";

const InnerTerritoryName = ({ Icon, name }: { Icon: IconType; name: string }) => {
  return (
    <span style={{ whiteSpace: "nowrap" }}>
      <Icon style={{ display: "inline" }} /> {name}
    </span>
  );
};

export const TerritoryName = ({ territoryUuid }: { territoryUuid: string }) => {
  const { data } = useGetAgenciesGeographiesAgenciesGet({}, [], {
    staleTime: 12 * 60 * 60 * 1000, // 12 hours
  });

  const agency = data?.find(a => a.uuid === territoryUuid);
  if (agency) {
    return <InnerTerritoryName Icon={PiDotsThree} name={agency.name} />;
  }
  return null;
};

export const RegionName = ({ regionUuid }: { regionUuid: string }) => {
  const { data } = useGetRegionsGeographiesRegionsGet({}, [], {
    staleTime: 12 * 60 * 60 * 1000, // 12 hours
  });

  const region = data?.find(r => r.uuid === regionUuid);
  if (region) {
    return <InnerTerritoryName Icon={PiDotsNine} name={region.name} />;
  }
  return null;
};

export const MutualizationAreaName = ({
  mutualizationAreaUuid,
}: {
  mutualizationAreaUuid: string | null;
}) => {
  const { data } = useGetMutualizationAreasGeographiesMutualizationAreasGet({}, [], {
    staleTime: 12 * 60 * 60 * 1000, // 12 hours
  });

  const mutArea = data?.find(ma => ma.uuid === mutualizationAreaUuid);
  if (mutArea) {
    return <InnerTerritoryName Icon={PiDotsSix} name={mutArea.name} />;
  }
  return null;
};
